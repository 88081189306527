import React from "react";
import { Link } from "react-router-dom";

const Collection = () => {
  return (
    <>
      <div className="apo-section apo-section-no-spacing">
        {/* Isotope*/}
        <div className=" apo-cols-0 apo-portfolio-container apo-style-5 columns-1 md:columns-2 w-[100%] h-[100%] overflow-hidden">
          {/* <div className="grid-sizer" /> */}
          <div className="apo-item apo-gucci-spring-2017-promotion mb-[1.6rem]">
            {/* Article*/}
            <article className="apo-project">
              <div className="apo-project-media">
                <a href="/gallery/weddings">
                  <img src="/images/img-147.jpg" alt="" />
                </a>
              </div>
              <div className="apo-project-content-wrap">
                <div className="apo-aligner-outer">
                  <div className="apo-aligner-inner">
                    <header className="apo-project-header">
                      <ul className="apo-project-categories">
                        <li>
                          <a href="/gallery/weddings">PhotoInfinity</a>
                        </li>
                      </ul>
                      <h2 className="apo-project-title">
                        <a href="/gallery/weddings">WEDDING</a>
                      </h2>
                    </header>
                  </div>
                </div>
              </div>
              <span className="apo-project-triangle" />
            </article>
          </div>
          <div className="apo-item apo-landscape mb-[1.6rem]">
            {/* Article*/}
            <article className="apo-project">
              <div className="apo-project-media">
                <Link to="/customer-gallery">
                  <img src="/images/img-149.jpg" alt="" />
                </Link>
              </div>
              <div className="apo-project-content-wrap">
                <div className="apo-aligner-outer">
                  <div className="apo-aligner-inner">
                    <header className="apo-project-header">
                      <ul className="apo-project-categories">
                        <li>
                          <Link to="/customer-gallery">PhotoInfinity</Link>
                        </li>
                      </ul>
                      <h2 className="apo-project-title">
                        <Link to="/customer-gallery">CUSTOMER GALLERY</Link>
                      </h2>
                    </header>
                  </div>
                </div>
              </div>
              <span className="apo-project-triangle" />
            </article>
          </div>
          <div className="apo-item apo-photo-retouching mb-[1.6rem]">
            {/* Article*/}
            <article className="apo-project">
              <div className="apo-project-media">
                <a href="/gallery/kids-world">
                  <img src="/images/img-146.jpg" alt="" />
                </a>
              </div>
              <div className="apo-project-content-wrap">
                <div className="apo-aligner-outer">
                  <div className="apo-aligner-inner">
                    <header className="apo-project-header">
                      <ul className="apo-project-categories">
                        <li>
                          <a href="/gallery/kids-world">PhotoInfinity</a>
                        </li>
                      </ul>
                      <h2 className="apo-project-title">
                        <a href="/gallery/kids-world">KIDS WORLD</a>
                      </h2>
                    </header>
                  </div>
                </div>
              </div>
              <span className="apo-project-triangle" />
            </article>
          </div>
          <div className="apo-item apo-close-up-macro mb-[1.6rem]">
            {/* Article*/}
            <article className="apo-project">
              <div className="apo-project-media">
                <a href="/gallery/portrait">
                  <img src="/images/img-148.jpg" alt="" />
                </a>
              </div>
              <div className="apo-project-content-wrap">
                <div className="apo-aligner-outer">
                  <div className="apo-aligner-inner">
                    <header className="apo-project-header">
                      <ul className="apo-project-categories">
                        <li>
                          <a href="/gallery/portrait">PhotoInfinity</a>
                        </li>
                      </ul>
                      <h2 className="apo-project-title">
                        <a href="/gallery/portrait">PORTRAIT</a>
                      </h2>
                    </header>
                  </div>
                </div>
              </div>
              <span className="apo-project-triangle" />
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collection;
