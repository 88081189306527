import React from "react";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="apo-page-dark-skin">
      <div className="apo-page">
        <div className="apo-section apo-huge apo-section-lightly md:pt-20">
          <div className="container-fluid p-3 md:px-20">
            <div className="row">
              <div className="col-lg-6">
                <img src="/images/3-scaled.jpg" alt="" />
              </div>
              <div className="col-lg-6">
                <h2 className=" text-[5rem] text-center md:text-left">
                  PhotoInfinity
                </h2>
                <p className="text-center md:text-left font-poppins text-[1.6rem] md:pr-6">
                  {" "}
                  Science is my profession but photography has always been my
                  passion. In photography there is a reality so subtle that it
                  becomes more real than reality. To me photography is all about
                  light and composition and most importantly{" "}
                  <strong>EMOTION</strong>. I focus mainly on Event photography
                  and Portraits and try my level best to capture moments such
                  that it becomes a memory you can savor and cherish for a
                  lifetime.
                </p>
                <h4 className="apo-section-sub-title text-center md:text-left">
                  Whether you are looking for a simple portrait or a larger
                  project, I would like to work with you. Feel free to go
                  through my portfolio for samples of my work.
                </h4>
              </div>
            </div>
            <div className="apo-cta mt-[9rem]">
              <p className="text-[1.6rem]">
                We are currently taking on new projects. Would you like to
                discuss yours?
              </p>
              <footer className="flex justify-center items-center gap-5">
                <Link
                  to="/contact"
                  className=" uppercase  apo-btn-small apo-btn-white "
                >
                  Contact Us
                </Link>
                <HiArrowRight className=" text-orange-400" />
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
